
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */

html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

figcaption,
figure {
    display: block
}

figure {
    margin: 1em 40px
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

main {
    display: block
}

pre {
    /* font-family: monospace, monospace; */
    font-size: 1em
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: inherit;
    font-weight: bolder
}

code,
kbd,
samp {
    /* font-family: monospace, monospace; */
    font-size: 1em
}

dfn {
    font-style: italic
}

small {
    /* font-size: 80% */
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

audio,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

img {
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

button,
input,
optgroup,
select,
textarea {
    /* font-family: sans-serif; */
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText
}

input {
    overflow: visible
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    box-sizing: border-box;
    display: table;
    max-width: 100%;
    padding: 0;
    color: inherit;
    white-space: normal
}

progress {
    display: inline-block;
    vertical-align: baseline
}

textarea {
    overflow: auto
}

details {
    display: block
}

summary {
    display: list-item
}

menu {
    display: block
}

canvas {
    display: inline-block
}

[hidden],
template {
    display: none
}

body,
html {
    /* color: rgba(0, 0, 0, .75); */
    font-size: 1.0em;
    /* font-family: Lato, Helvetica Neue, Helvetica, sans-serif; */
    font-variant-ligatures: common-ligatures;
    line-height: 1.67;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.app--dark .layout__panel--editor,
.app--dark .layout__panel--preview {
    color: hsla(0, 0%, 100%, .75)
}

blockquote,
dl,
ol,
p,
pre,
ul {
    margin: 1.2em 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1.4em 0;
    line-height: 1.33;
    font-weight: 700;
}

h2:after {
    content: "";
    display: block;
    position: relative;
    top: .45em;
    border-bottom: 1px solid hsla(0, 0%, 50%, .33)
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin: 0
}

dt {
    font-weight: 700
}

a {
    color: #0c93e4;
    /* text-decoration: underline; */
    text-decoration-skip: auto;
}

a:focus,
a:hover {
    text-decoration: underline;
}

code,
pre,
samp {
    /* font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Courier New,"맑은고딕",'Malgun Gothic'; */
    font-family: "SF Mono", "Consolas", "Menlo";
    font-size: .85em
}

code *,
pre *,
samp * {
    font-size: inherit
}

blockquote {
    color: rgba(0, 0, 0, .7);
    padding-left: 1.5em;
    border-left: 5px solid rgba(0, 0, 0, .1)
}

.app--dark .layout__panel--editor blockquote,
.app--dark .layout__panel--preview blockquote {
    color: hsla(0, 0%, 100%, .4);
    border-left-color: hsla(0, 0%, 100%, .1)
}

code {
    background-color: rgba(0, 0, 0, .05);
    border-radius: 3px;
    padding: 2px 4px
}

hr {
    border: 0;
    border-top: 1px solid hsla(0, 0%, 50%, .33);
    margin: 2em 0
}

pre>code {
    /* background-color: rgba(0, 0, 0, .05); */
    background-color: #f3f3f3;
    color: #444;
    display: block;
    padding: 1.0em;
    -webkit-text-size-adjust: none;
    overflow-x: auto;
    white-space: pre-wrap;
}

.toc ul {
    list-style-type: none;
    /* padding-left: 20px */
}

table {
    margin: 20px 0;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    border: solid 1px #ccc;
}

table tr th {
    background-color: #f8f9f9;
}

td,
th {
    border-right: 1px solid #dcdcdc;
    padding: 8px 12px
}

td:last-child,
th:last-child {
    border-right: 0
}

td {
    border-top: 1px solid #dcdcdc
}

mark {
    background-color: #f8f840;
    padding-left: 0;
    padding-right: 0;
}

mark.add {
    background-color: #f8f840;
}

mark.del {
    background-color: #bbb;
}

kbd {
    /* font-family: Lato, Helvetica Neue, Helvetica, sans-serif; */
    background-color: #fff;
    border: 1px solid rgba(63, 63, 63, .25);
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(63, 63, 63, .25);
    color: #333;
    display: inline-block;
    font-size: .8em;
    margin: 0 .1em;
    padding: .1em .6em;
    white-space: nowrap
}

abbr[title] {
    border-bottom: 1px dotted #777;
    cursor: help
}

img {
    max-width: 100%
}

.task-list-item {
    list-style-type: none
}

.task-list-item-checkbox {
    margin: 0 .2em 0 -1.3em
}

/*
.footnote {
    font-size: .8em;
    position: relative;
    top: -.25em;
    vertical-align: top
}
*/

.page-break-after {
    page-break-after: always
}

.abc-notation-block {
    overflow-x: auto!important
}

.stackedit__html {
    margin-bottom: 180px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 750px
}

.stackedit__toc ul {
    padding: 0
}

.stackedit__toc ul a {
    margin: .5rem 0;
    padding: .5rem 1rem
}

.stackedit__toc ul ul {
    color: #888;
    font-size: .9em
}

.stackedit__toc ul ul a {
    margin: 0;
    padding: .1rem 1rem
}

.stackedit__toc li {
    display: block
}

.stackedit__toc a {
    display: block;
    color: inherit;
    text-decoration: none
}

.stackedit__toc a:active,
.stackedit__toc a:focus,
.stackedit__toc a:hover {
    background-color: rgba(0, 0, 0, .075);
    border-radius: 3px
}

.stackedit__left {
    position: fixed;
    display: none;
    width: 250px;
    height: 100%;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none
}

@media (min-width:1060px) {
    .stackedit__left {
        display: block
    }
}

.stackedit__right {
    position: absolute;
    right: 0;
    top: 0;
    left: 0
}

@media (min-width:1060px) {
    .stackedit__right {
        left: 250px
    }
}

.stackedit--pdf blockquote {
    border-left-color: #ececec
}

.stackedit--pdf .stackedit__html {
    padding-left: 0;
    padding-right: 0;
    max-width: none
}

h1 {
  font-size: 1.5em;
  }

h2 {
  font-size: 1.4em;
  }

h3 {
  font-size: 1.3em; }

h4 {
  font-size: 1.2em; }

h5 {
  font-size: 1.1em; }

h6 {
  color: #777777;
  font-size: 1.0em; }


.page-content fieldset {
    border: solid 2px #ddd;
    padding:10px 20px;
    background:#fcfcfc;
    margin: 20px 0;
}

.page-content legend {
    width: auto;
    border: none;
    margin:0;
    padding: 0 5px;
    font-size:14px;
    font-weight:bold;
    color:#999;
}

.wp-content p {
    margin: 5px 0;
}
a.wiki_title {
    color: #8B0000;
    text-decoration: none;
    font-size: 16px;
}
a.inner_link {
    color: #8B0000;
    text-decoration: none;
}
a.wiki_title:hover, a.inner_link:hover {
    text-decoration: underline;
}
#search-results {
    width: 100%;
    cursor: pointer;
    padding:0;
    margin:0;
    background-color: #f2f2f2;
    border-top:none;
    border-radius: 0; /* 모서리의 radius를 0으로 설정 */
    font-weight:bold;
}
#search-results li {
    margin: 0;
    padding: 5px 30px;
}
.result-item {
    white-space: normal;
    padding: 0 15px;
    color:#000;
}

.dropdown-menu .active {
    background-color: #000;
    outline: none;
    color:#fff;
}

a.wiki_link {
    color: #8B0000;
    text-decoration: none;
}
a.wiki_link:hover {
    text-decoration: underline;
}
.popover{
    max-width: 500px; /* Max Width of the popover (depending on the container!) */
}


.toc-footer {
    
}

.toc-footer a {
    color: #333; /* 링크 기본 색상 */
    text-decoration: none; /* 링크 밑줄 제거 */
}

.toc-footer a:hover,
.toc-footer a:focus {
    color: #333; /* 링크 hover 또는 focus 시 색상 */
    text-decoration: underline; /* 링크 hover 또는 focus 시 밑줄 추가 */
}

.search-title {
    border-bottom: solid 1px #000;
    padding-bottom:10px;
}
.card {
    padding:20px;
}
.search em {
    background-color: #ffe69c; /* 노란색 배경 */
    color: #000; /* 검은색 글씨 */
    /* padding: 2px 4px; */
    font-style: normal; /* 기울임체 제거 */
    /* font-weight: bold; 볼드체 적용 */
}
.no-content {
    background-color: #f7f7f7; /* 연한 회색 배경 */
    border: 1px dashed #ccc; /* 점선 테두리 */
    padding: 10px;
    text-align: center; /* 텍스트 중앙 정렬 */
    font-style: italic; /* 기울임체 적용 */
}
.subtitle {
    border-bottom: solid 1px #ccc;
    margin-bottom:10px;
    padding-bottom:10px;
    font-size: 20px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: var(--base-text-weight-semibold, 600);
}

.find-item + .find-item {
  margin-top: 15px; /* 원하는 마진 값을 설정하세요 */
}
.find-title {
    font-size: 16px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: var(--base-text-weight-semibold, 600);
}
.find-type {
    font-weight: bold;
    /* text-decoration: underline; */
    font-size:14px;
    margin-bottom:10px;
}
.find-content {
    white-space: pre-line;
    padding:10px;
    background: #f2f2f2;
}
.find-success {
    margin: 20px 0;
}
.card {
    border-radius: 0; /* 모서리의 radius를 0으로 설정 */
    border: solid 1px #ccc;
}
.card-header {
    background-color: #ddd;
}
.card-header:first-child {
    border-radius: 0; /* 모서리의 radius를 0으로 설정 */
}
.pagination {
    margin:0;
}
.page img {
    margin-top: 10px;
    margin-bottom: 10px;
}
